import React, { useState } from "react";
import moment from "moment";
import { Recharge } from "../../models/Recharges";

type RechargeListProps = {
  recharges: Recharge[];
  onDownloadReceipt: (item: Recharge) => void;
  onContinueTransaction: (item: Recharge) => void; // New prop for handling continued transactions
};

const RechargeList: React.FC<RechargeListProps> = ({
  recharges,
  onDownloadReceipt,
  onContinueTransaction, // Handle continued transactions
}) => {
  const [openRechargeId, setOpenRechargeId] = useState<number | null>(null);
  const [loadingRechargeId, setLoadingRechargeId] = useState<number | null>(
    null
  );

  const toggleDetails = (id: number) => {
    setOpenRechargeId(openRechargeId === id ? null : id);
  };

  const handleContinueTransaction = async (item: Recharge) => {
    setLoadingRechargeId(item.id);
    await onContinueTransaction(item);
    setLoadingRechargeId(null);
  };

  return (
    <div>
      <div className="card stretch stretch-full">
        <div className="card-header">Recent Payments</div>
        <div className="card-body">
          <div className="recent-activity p-4 pb-0">
            <div className="mb-4 pb-2 d-flex justify-content-between">
              <h5 className="fw-bold">Recent Operations:</h5>
            </div>
            <ul className="list-unstyled activity-feed">
              {recharges.map((item, index) => {
                const styleClass =
                  index % 2 === 0 ? "feed-item-success" : "feed-item-info";

                return (
                  <li
                    key={item.id}
                    className={`d-flex flex-column feed-item ${styleClass}`}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <span className="text-truncate-1-line lead_date">
                          Recharge of{" "}
                          <span className="fw-bold text-black">
                            {item.amount} {item.currency}
                          </span>{" "}
                          <span className="date">
                            [{moment(item.date).format("DD/MM/YYYY HH:mm")}]
                          </span>
                        </span>
                        <span className="text">
                          Status:{" "}
                          <span
                            className={`fw-bold text-${
                              item.payment_status === "succeeded"
                                ? "green"
                                : "primary"
                            }`}
                          >
                            {item.payment_status.charAt(0).toUpperCase() +
                              item.payment_status.slice(1)}
                          </span>
                        </span>
                      </div>
                      <button
                        className="btn btn-sm btn-light-brand my-3 my-xxl-0 my-md-3 my-md-0"
                        onClick={() => toggleDetails(item.id)}
                      >
                        {openRechargeId === item.id
                          ? "Hide Details"
                          : "Details"}
                      </button>
                    </div>
                    {/* Collapsible Section for Recharge Details */}
                    {openRechargeId === item.id && (
                      <div className="collapse show mt-3">
                        <div className="card card-body">
                          <p>
                            <strong>Amount:</strong> {item.amount}{" "}
                            {item.currency}
                          </p>
                          <p>
                            <strong>Date:</strong>{" "}
                            {moment(item.date).format("DD/MM/YYYY HH:mm")}
                          </p>
                          <p>
                            <strong>Status:</strong> {item.payment_status}
                          </p>
                          <p>
                            <strong>Recharge Code:</strong> {item.recharge_code}
                          </p>
                          {item.payment_status === "succeeded" ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() => onDownloadReceipt(item)}
                            >
                              Download Receipt
                            </button>
                          ) : item.payment_status === "pending" ? (
                            <div></div>
                          ) : // <button
                          //   type="button"
                          //   className="btn btn-primary"
                          //   onClick={() => handleContinueTransaction(item)}
                          //   disabled={loadingRechargeId === item.id}
                          // >
                          //   {loadingRechargeId === item.id && (
                          //     <span className="loader"></span>
                          //   )}
                          //   Continue Transaction
                          // </button>
                          null}
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeList;
