import React, { useState } from "react";
import { UilEditAlt, UilTrashAlt } from "@iconscout/react-unicons";

interface Pricing {
  Country: string;
  iso2: string;
  PhoneCode: string;
  Price: number;
  PricePartner: number;
  Operator?: string;
}

interface PricingTableProps {
  pricings: Pricing[];
  isAdmin?: boolean;
  handleSelectPricing?: (pricing: Pricing) => void;
}

const PricingTable: React.FC<PricingTableProps> = ({
  pricings,
  handleSelectPricing,
  isAdmin = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter pricings based on search term
  const filteredPricings = pricings.filter((pricing) =>
    pricing.Country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card stretch stretch-full">
          <div className="card-body p-0">
            <div className="table-responsive ">
              <div className="p-3">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Search by country"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <table className="table table-hover" id="customerList">
                <thead>
                  <tr>
                    <th className="text-nowrap" style={{ width: "20%" }}>
                      Country
                    </th>
                    <th className="text-nowrap" style={{ width: "15%" }}>
                      Phone Code
                    </th>
                    <th className="text-nowrap" style={{ width: "20%" }}>
                      Price
                    </th>
                    {isAdmin && (
                      <>
                        <th className="text-nowrap" style={{ width: "20%" }}>
                          Price Partner
                        </th>
                        <th
                          className="text-nowrap text-center"
                          style={{ width: "25%" }}
                        >
                          Actions
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredPricings.length > 0 ? (
                    filteredPricings.map((pricing, index) => (
                      <tr key={index} className="single-item">
                        <td>
                          <span className="text-truncate-1-line">
                            {pricing.Country ?? "N/A"}{" "}
                            {pricing.Operator && `(${pricing.Operator})`}
                          </span>
                        </td>
                        <td>
                          <div className="hstack gap-3 text-black">
                            <span className="text-truncate-1-line">
                              {pricing.iso2 ?? "N/A"} (
                              {pricing.PhoneCode ?? "N/A"})
                            </span>
                          </div>
                        </td>
                        <td className="align-middle">
                          {pricing.Price ?? "N/A"} €
                        </td>
                        {isAdmin && handleSelectPricing && (
                          <>
                            <td className="align-middle">
                              {pricing.PricePartner ?? "N/A"} €
                            </td>
                            <td className="text-center">
                              <div className="d-flex justify-content-center gap-2">
                                <button
                                  className="btn btn-warning btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editModal"
                                  onClick={() => handleSelectPricing(pricing)}
                                >
                                  <UilEditAlt />
                                  <span>Edit</span>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  <UilTrashAlt />
                                  <span>Delete</span>
                                </button>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center my-5">
                        Your pricing list will appear here
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
