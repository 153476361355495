export const homepageTourSteps = [
  {
    target: ".login-btn", // Example element on the homepage
    content: "New on this app? You can login here..",
  },
  {
    target: ".home-feature-2",
    content: "Here is the second feature.",
  },
  // Add more steps as needed...
];

export const dashboardTourSteps = [
  {
    target: ".row.balance-button",
    content:
      "You can preview your available balance here and top up your account.",
  },
  {
    target: ".dashboard-feature-2",
    content: "Another dashboard feature.",
  },
  // Add more steps as needed...
];
