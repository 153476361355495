import React from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import Footer from "../../components/Footer";
import {
  UilAngleLeftB,
  UilPaperclip,
  UilEllipsisH,
  UilEditAlt,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import { Payment } from "../../models/Payment";

type Props = {};

const PaymentList = (props: Props) => {
  const payments: Payment[] = [
    {
      amount: 6000,
      paymentId: "nufi3dddd",
      paymentType: "card",
      date: "08/07/2024",
      status: "Pending",
      customer: "nufiCUs34tyu",
    },
    {
      amount: 6000,
      paymentId: "nufi3dddd",
      paymentType: "card",
      date: "08/07/2024",
      status: "Initiated",
      customer: "nufiCUs34tyu",
    },
    {
      amount: 6000,
      paymentId: "nufi3dddd",
      paymentType: "card",
      date: "08/07/2024",
      status: "Initiated",
      customer: "nufiCUs34tyu",
    },
  ];
  const getStatusBadgeClass = (status: any) => {
    switch (status) {
      case "Pending":
        return "bg-soft-warning text-warning";
      case "Sent":
        return "bg-soft-success text-success";
      case "Failed":
        return "bg-soft-danger text-danger";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Payment List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                  <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>Print</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div
                    className="page-header-right-open-toggle"
                  >
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th className="wd-30">
                                <div className="btn-group mb-1">
                                  <div className="custom-control custom-checkbox ms-1">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="checkAllCustomer"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="checkAllCustomer"
                                    ></label>
                                  </div>
                                </div>
                              </th>
                              <th>Customer</th>
                              <th>Payment ID</th>
                              <th>Amount Paid</th>
                              <th>Date Due</th>
                              <th>Payment Type</th>
                              <th>Status</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments &&
                              payments.map((payment, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <div className="item-checkbox ms-1">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox"
                                          id="checkBox_1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="checkBox_1"
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="text-truncate-1-line">
                                      {payment.customer}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {payment.paymentId}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {payment.amount}
                                    </div>
                                  </td>
                                  <td>{payment.date}</td>
                                  <td>{payment.paymentType}</td>
                                  <td>
                                    <span
                                      className={`badge ${getStatusBadgeClass(
                                        payment.status
                                      )}`}
                                    >
                                      {payment.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-2 justify-content-end">
                                      <div className="dropdown ">
                                        <button
                                          className="avatar-text avatar-md"
                                          data-bs-toggle="dropdown"
                                          data-bs-offset="0,21"
                                        >
                                          <UilEllipsisH className="small-svg" />
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <button
                                              className="dropdown-item border-0"
                                              data-bs-toggle="modal"
                                              data-bs-target="#editModal"
                                            >
                                              <UilEditAlt className="me-3" />
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <button
                                              className="dropdown-item border-0"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteModal"
                                            >
                                              <UilTrashAlt className="me-3" />
                                              <span>Delete</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {payments?.length === 0 && (
                          <h5 className="text-center my-5">
                            Your payment list will appear here
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default PaymentList;
