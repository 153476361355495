import { SMSStatus } from "../models/SMS";

export const getStatusBadgeClass = (status: SMSStatus) => {
  switch (status) {
    case "Partially_delivered":
      return "bg-soft-warning text-warning";
    case "Pending":
      return "bg-soft-info text-info";
    case "Sent":
      return "bg-soft-success text-success";
    case "Delivered":
      return "bg-soft-success text-success";
    case "Failed":
      return "bg-soft-danger text-danger";
    default:
      return "bg-soft-secondary text-secondary";
  }
};

export const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const formatForWhatsApp = (html: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const formatNode = (node: Node): string => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent || "";
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      switch (node.nodeName) {
        case "STRONG":
        case "B":
          return `*${node.textContent}*`; // Bold
        case "EM":
        case "I":
          return `_${node.textContent}_`; // Italics
        case "U":
          return `~${node.textContent}~`; // Underline
        case "P":
          return formatChildren(node) + "\n"; // Add line break for paragraphs
        case "UL":
        case "OL":
          return formatList(node); // Process lists
        case "A":
          return node.textContent || ""; // Links
        case "BR":
          return "\n"; // Handle line breaks
        default:
          return formatChildren(node); // Process other elements
      }
    }
    return "";
  };

  const formatChildren = (parentNode: Node): string => {
    return Array.from(parentNode.childNodes).map(formatNode).join("");
  };

  const formatList = (listNode: Node): string => {
    return Array.from(listNode.childNodes)
      .map((item: Node) => {
        if (item.nodeName === "LI") {
          return `• ${formatChildren(item)}`; // Bullet point
        }
        return "";
      })
      .join("\n");
  };

  const formattedText = formatChildren(tempDiv);
  return formattedText.trim(); // Trim leading/trailing whitespace
};

export const getTextFromHtml = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
