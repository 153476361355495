import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { SMS, SMSStatus } from "../../models/SMS";
import { getAllSms } from "../../redux/slices/smsSlice";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { AppDispatch } from "../../redux/store";
import { getStatusBadgeClass, truncateText } from "../../utils/functions";

const SmsHistoric: React.FC = () => {
  const [messages, setMessages] = useState<SMS[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<SMS | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Default items per page
  // const [filters, setFilters] = useState<{}>({});

  const dispatch: AppDispatch = useDispatch();

  // Fetch SMS history
  const fetchSms = useCallback(async () => {
    try {
      const payload: Record<string, any> = { page: currentPage };
      if (itemsPerPage !== -1) payload.limit = itemsPerPage;

      // Uncomment this section when filters are implemented
      // if (Object.keys(filters).length > 0) payload.filters = filters;

      const res: any = await dispatch(getAllSms(payload)).unwrap();
      if (res.status === -1) throw new Error("Failed to fetch messages");

      setMessages(res.data || []);
      setTotalPages(
        Math.ceil(res.total / (itemsPerPage !== -1 ? itemsPerPage : res.total))
      );
    } catch (err) {
      console.error("Error fetching SMS:", err);
    }
  }, [dispatch, currentPage, itemsPerPage /* , filters */]);

  useEffect(() => {
    fetchSms();
  }, [fetchSms]);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value, 10);
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Uncomment this function when filters are implemented
  // const handleFilterChange = (newFilters: { content?: string; status?: SMSStatus }) => {
  //   setFilters(newFilters);
  //   setCurrentPage(1); // Reset to first page when filters change
  // };

  const handleRowClick = (message: SMS) => setSelectedMessage(message);
  const handleBackClick = () => setSelectedMessage(null);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content">
              <div className="d-flex p-3 mb-2 w-100 justify-content-between align-items-center">
                {/* Placeholder for future filters */}
                {/* <div>
                  <label htmlFor="filter-status">Status:</label>
                  <select
                    id="filter-status"
                    onChange={(e) =>
                      handleFilterChange({ ...filters, status: e.target.value as SMSStatus })
                    }
                    className="ms-2"
                  >
                    <option value="">All</option>
                    <option value="SENT">Sent</option>
                    <option value="FAILED">Failed</option>
                  </select>
                </div> */}
                <div>
                  <span>Items per page:</span>
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="ms-2"
                  >
                    {[5, 10, 20, 30].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                    <option value={-1}>All</option>
                  </select>
                </div>
              </div>

              {/* SMS History Table */}
              <div className="row">
                <div className="col-xxl-8">
                  <div className="card stretch stretch-full">
                    {selectedMessage ? (
                      <>
                        <div className="card-header">
                          <button
                            onClick={handleBackClick}
                            className="btn border-0 bg-transparent"
                          >
                            Back
                          </button>
                          <h5 className="card-title text-center">
                            SMS Details
                          </h5>
                        </div>
                        <div className="card-body">
                          {/* Selected SMS Details */}
                          <h6>SMS Code: {selectedMessage.code_msg}</h6>
                          <p>Content: {selectedMessage.smsContent}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card-header">
                          <h5 className="card-title">SMS History</h5>
                        </div>
                        <div className="card-body custom-card-action p-0">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>SMS Code</th>
                                  <th>Content</th>
                                  <th>Status</th>
                                  <th>Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {messages.length > 0 ? (
                                  messages.map((message: SMS) => (
                                    <tr key={message.id}>
                                      <td>{message.code_msg}</td>
                                      <td title={message.smsContent}>
                                        {truncateText(message.smsContent, 20)}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge ${getStatusBadgeClass(
                                            message.status as SMSStatus
                                          )}`}
                                        >
                                          {message.status}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            handleRowClick(message)
                                          }
                                          className="btn btn-link"
                                        >
                                          Details
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={4} className="text-center">
                                      No records found.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SmsHistoric;
