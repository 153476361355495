import axiosInstance from "./axiosInstance";

const createSession = async (data: { phoneNumber: string }) => {
  const response = await axiosInstance.post("session/create", data);
  return response.data;
};

const sendFromFile = async (formData: FormData) => {
  const response = await axiosInstance.post(
    "whatsapp-message/send-message",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const initSession = async (phoneNumber: string, userPackageId: number) => {
  const response = await axiosInstance.get(
    `whatsapp-message/initialize/${phoneNumber}/package/${userPackageId}`
  );
  return response.data;
};

const sendMessageToContacts = async (data: any) => {
  const response = await axiosInstance.post(
    "whatsapp-message/send-message",
    data
  );
  return response.data;
};

const sendMessageWithImage = async (formData: FormData) => {
  const response = await axiosInstance.post(
    "whatsapp-message/media/send-message",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

// const extractWhatsappGroups = async (
//   phoneNumber: string,
//   packageId: number,
//   params: {
//     page: number;
//     pageSize: number;
//   }
// ) => {
//   const response = await axiosInstance.get(
//     `whatsapp-message/groups/${packageId}/${phoneNumber}`,
//     { params }
//   );
//   return response.data;
// };

const extractWhatsappGroups = async (
  phoneNumber: string,
  packageId: number,
  params?: {
    page?: number;
    pageSize?: number;
  }
) => {
  const queryParams = params ? { params } : {}; // Conditionally add params if available
  const response = await axiosInstance.get(
    `whatsapp-message/groups/${packageId}/${phoneNumber}`,
    queryParams
  );
  return response.data;
};

const extractContactsFromGroup = async (data: {
  phoneNumber: string;
  groupIds: string[];
}) => {
  const response = await axiosInstance.post(
    `whatsapp-message/contacts/extract`,
    data
  );
  return response.data;
};

const getUserInitSessions = async () => {
  const response = await axiosInstance.get(
    `whatsapp-message/all/initialize/whats-phone`
  );
  return response.data;
};

const getMessageHistory = async (params: {
  page?: number;
  limit?: number;
  filters?: any;
}) => {
  const response = await axiosInstance.get(`task-sms/whatsapp`, { params });
  return response.data;
};

export const whatsappService = {
  createSession,
  sendFromFile,
  initSession,
  sendMessageToContacts,
  extractWhatsappGroups,
  extractContactsFromGroup,
  getUserInitSessions,
  getMessageHistory,
  sendMessageWithImage,
};
