import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SOCKET_SERVER_URL_PROD
    : process.env.REACT_APP_SOCKET_SERVER_URL_DEV;

export const socket = io(URL as string, {
  autoConnect: false,
});
