import React, { useState } from "react";
import { UilArrowCircleLeft, UilAngleRightB } from "@iconscout/react-unicons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { confirmFlutterwavePayment } from "../../redux/slices/accountSlice";

type FlutterwaveCheckoutProps = {
  onBack: () => void;
  onSuccess: () => void;
};

const FlutterwaveCheckout: React.FC<FlutterwaveCheckoutProps> = ({
  onBack,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.currentUser);

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string(),
    // currency: Yup.string().required("Currency is required"),
  });

  const handleSuccess = () => {
    formik.resetForm();
    onSuccess();
  };

  const initiateFlutterwavePayment = async (values: any) => {
    setLoading(true);
    try {
      dispatch(confirmFlutterwavePayment(values))
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          handleSuccess();
        });
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Initialize useFormik
  const formik = useFormik({
    initialValues: {
      name: `${user?.firstname} ${user?.lastname}`,
      amount: "",
      email: user?.email || "",
      phone: user?.phone || "",
      // currency: "EUR",
    },
    validationSchema,
    onSubmit: (values) => {
      handleFlutterPayment({
        callback: (response) => {
          initiateFlutterwavePayment(response);
          closePaymentModal(); // This will close the modal programmatically
        },
        onClose: () => {},
      });
    },
  });

  const publicKey = process.env.REACT_APP_FLWPUBK_TEST;

  if (!publicKey) {
    console.error(
      "Flutterwave public key is missing. Please set it in the environment variables."
    );
  }

  // Flutterwave config
  const config = {
    public_key: publicKey || "",
    tx_ref: `${Date.now()}`,
    amount: Number(formik.values.amount),
    currency: "EUR",
    payment_options: "mobilemoneyfranco",
    customer: {
      email: formik.values.email,
      phone_number: formik.values.phone,
      name: formik.values.name,
    },
    customizations: {
      title: "NufiSMS Balance Top-up",
      description:
        "Securely recharge your NufiSMS account to access our messaging services",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  // Initialize Flutterwave payment outside of onSubmit
  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className="container">
      <button
        onClick={onBack}
        className="btn btn-sm btn-light-brand text-black border-black mb-3"
      >
        <UilArrowCircleLeft />
        Back to balance
      </button>
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="mb-3 col-12">
                <label htmlFor="amount" className="fw-bold text-black">
                  Amount to Topup
                </label>
                <div className="input-group">
                  {/* <div className="input-group-text p-0 rounded-end-0">
                    <select
                      name="currency"
                      id="currency"
                      className="form-select border-0 rounded-0 bg-gray"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ minWidth: "80px" }} // Adjust width as needed
                    >
                      <option value="XAF">XAF</option>
                      <option value="XOF">XOF</option>
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                      <option value="EUR">EUR</option>
                    </select>
                  </div> */}
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    placeholder="Amount to topup"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ minWidth: "100px" }} // Adjust as needed
                  />
                </div>
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="text-danger">{formik.errors.amount}</div>
                ) : null}
              </div>

              <div className="mb-3 col-12">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={!(formik.isValid && formik.dirty) || loading}
                >
                  {loading && <span className="loader"></span>}
                  Make payment <UilAngleRightB />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FlutterwaveCheckout;
