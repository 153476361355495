import { useFormik } from "formik";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { createClientValidationSchema } from "../../validation/FormikValues";
import toast from "react-hot-toast";
import { adminServices } from "../../services/adminService";
import { useParams } from "react-router-dom";

type Props = {};

const Profile = (props: Props) => {
  const [isloading, setIsloading] = React.useState(false);
  const { userCode } = useParams();

  const handleSubmit = () => {};
  const formik = useFormik({
    initialValues: {
      firstname: "", // Default empty string instead of undefined
      lastname: "",
      email: "",
      phone: "",
      country: "",
      address: "",
      company: "",
      status: "",
      state: "",
      city: "",
      login: "",
    },
    validationSchema: createClientValidationSchema,
    onSubmit: handleSubmit,
  });

  const fetchClient = async () => {
    try {
      if (userCode) {
        setIsloading(true);
        await adminServices
          .getClientByUserCode(userCode)
          .then((res: any) => {
            if (res.status === -1) {
              return;
            }
            formik.setValues({
              firstname: res.data.firstname || "",
              lastname: res.data.lastname || "",
              email: res.data.email || "",
              phone: res.data.phone || "",
              country: res.data.country || "",
              address: res.data.address || "",
              company: res.data.company || "",
              status: res.data.status || "",
              state: res.data.state || "California",
              city: res.data.city || "Los Angeles",
              login: res.data.login || "john.doe",
            });
          })
          .catch((err: any) => {})
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);

  return (
    <div
      className="tab-pane fade show active p-4"
      id="overviewTab"
      role="tabpanel"
    >
      <div className="about-section mb-5">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="fw-bold mb-0">Profile Details</h5>
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-md-4">
          <label htmlFor="firstname">Firstname</label>
          <input
            type="text"
            className="form-control"
            placeholder="Firstname"
            id="firstname"
            {...formik.getFieldProps("firstname")}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="text-danger">{formik.errors.firstname}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="lastname">Lastname</label>
          <input
            type="text"
            className="form-control"
            placeholder="Lastname"
            id="lastname"
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="text-danger">{formik.errors.lastname}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="company">Company Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Company Name"
            id="company"
            {...formik.getFieldProps("company")}
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="text-danger">{formik.errors.company}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="email">Email</label>
          <input
            className="form-control"
            placeholder="Email"
            id="email"
            type="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            className="form-control"
            placeholder="Country"
            id="country"
            {...formik.getFieldProps("country")}
          />
          {formik.touched.country && formik.errors.country ? (
            <div className="text-danger">{formik.errors.country}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="phone">Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            international
            id="phone"
            defaultCountry="CM"
            className="form-control"
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={(phone) => formik.setFieldValue("phone", phone)}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-danger">{formik.errors.phone}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="login">Login</label>
          <input
            className="form-control"
            placeholder="Login"
            id="login"
            type="email"
            {...formik.getFieldProps("login")}
          />
          {formik.touched.login && formik.errors.login ? (
            <div className="text-danger">{formik.errors.login}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Address"
            id="address"
            {...formik.getFieldProps("address")}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="text-danger">{formik.errors.address}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="state">State</label>
          <input
            type="text"
            className="form-control"
            placeholder="State"
            id="state"
            {...formik.getFieldProps("state")}
          />
          {formik.touched.state && formik.errors.state ? (
            <div className="text-danger">{formik.errors.state}</div>
          ) : null}
        </div>
        <div className="mb-3 col-md-4">
          <label htmlFor="city">City</label>
          <input
            type="text"
            className="form-control"
            placeholder="City"
            id="city"
            {...formik.getFieldProps("city")}
          />
          {formik.touched.city && formik.errors.city ? (
            <div className="text-danger">{formik.errors.city}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Profile;
