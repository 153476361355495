import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faqItems } from "../../data/Faqs";
import { UilAngleDown, UilAngleRight } from "@iconscout/react-unicons";

type Props = {};

const FrequentlyAskedQuestions = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the current FAQ
  };

  const getColorClass = (index: number) => {
    const colors = [
      {
        bg: "bg-soft-success",
        text: "text-success",
        border: "border-soft-success",
      },
      { bg: "bg-soft-info", text: "text-info", border: "border-soft-info" },
      {
        bg: "bg-soft-warning",
        text: "text-warning",
        border: "border-soft-warning",
      },
      {
        bg: "bg-soft-primary",
        text: "text-primary",
        border: "border-soft-primary",
      },
    ];
    return colors[index % colors.length]; // Rotate colors based on index
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />
        </Link>
      </div>
      <div className="my-5">
        <h2 className="text-center mb-4">
          <strong>Frequently Asked Questions</strong>
        </h2>
        <p className="text-justify text-center m-0 fs-16">
          These are the most commonly asked questions about NufiSMS app.
        </p>
        <p className="text-justify text-center m-0 fs-16">
          Can't find what you're looking for?{" "}
          <a
            href="mailto:support@nufisms.com"
            className="text-decoration-underline"
          >
            Contact our friendly team
          </a>
        </p>
      </div>

      {faqItems.map((faq, index) => {
        const isActive = index === activeIndex;
        const colorClass = getColorClass(index);
        return (
          <div key={index} className="col-12 col-lg-6 mx-auto">
            {" "}
            {/* Full width on small, half on large */}
            <div
              className="d-flex align-items-center justify-content-between cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <div className="d-flex align-items-center">
                <div
                  className={`avatar-text avatar-lg ${colorClass.bg} ${colorClass.text} ${colorClass.border} rounded`}
                >
                  {faq.icon}
                </div>
                <div className="ms-3">
                  <h5 className="fw-semibold">{faq.question}</h5>
                </div>
              </div>
              <div className="avatar-text avatar-md">
                {isActive ? <UilAngleDown /> : <UilAngleRight />}
              </div>
            </div>
            {isActive && (
              <div className="mt-2">
                <p className="fs-16 text-muted fw-normal">{faq.answer}</p>
              </div>
            )}
            <hr className="border-dashed my-3" />
          </div>
        );
      })}
    </div>
  );
};

export default FrequentlyAskedQuestions;
