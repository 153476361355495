import React, { useState } from "react";
import { Session } from "./whatsApp/UserWhatsappSessions";
import { ApiService } from "../services/apiService";
import toast from "react-hot-toast";

type GenerateApiKeysProps = {
  sessions: Session[];
  getUserApiKeys: () => void;
  setView: (view: "main" | "init" | "generate") => void;
};

const GenerateApiKeys: React.FC<GenerateApiKeysProps> = ({
  sessions,
  getUserApiKeys,
  setView,
}) => {
  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    sessions[0]?.phoneNumber || ""
  );

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const data = {
        phoneNumber,
        ipAddress: ipAddress, // Optional field
      };

      const result = await ApiService.generateApiKeys(data);
      if (result.status === -1) {
        toast.error(
          result.message || "An error occurred. Please try again later"
        );
        return;
      }
      toast.success("API keys generated successfully!");
      setIpAddress("");
      getUserApiKeys();
      setView("main");
    } catch (error) {
      toast.error("Failed to generate API keys. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Disable the button if no IP address or phone number is entered
  const isDisabled = loading || !ipAddress || !phoneNumber;

  return (
    <div className="card stretch">
      <div className="card-header">
        <h5 className="card-title">Generate New API Keys</h5>
      </div>
      <div className="card-body">
        <div className="mb-4">
          <label className="fw-bold fs-14 text-black mb-1" htmlFor="ip">
            Your Server IP Address *
          </label>
          <input
            type="text"
            name="ip"
            id="ip"
            placeholder="IP Address"
            className="form-control"
            value={ipAddress}
            onChange={(e) => setIpAddress(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label
            className="fw-bold fs-14 text-black mb-1"
            htmlFor="phoneNumber"
          >
            Sender Phone Number *
          </label>
          <select
            id="phoneNumber"
            className="form-select"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          >
            {sessions.map((session, index) => (
              <option key={index} value={session.phoneNumber}>
                {session.phoneNumber}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={isDisabled}
          className="btn btn-primary"
          onClick={handleGenerate}
        >
          {loading && <span className="loader"></span>}
          Generate
        </button>
      </div>
    </div>
  );
};

export default GenerateApiKeys;
