import React, { useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { RoleData } from "../pages/app/ManageRoles";
import { adminServices } from "../services/adminService";

type CreateRoleFormProps = {
  isloading: boolean;
  setIsloading: (value: boolean) => void;
  getAllRoles: () => void;
};

const options = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const CreateRoleForm: React.FC<CreateRoleFormProps> = ({
  isloading,
  setIsloading,
  getAllRoles,
}) => {
  const [roleData, setRoleData] = useState<RoleData>({
    name: "",
    code_role: "",
    status: true,
  });

  const handleCreate = async () => {
    try {
      setIsloading(true);
      const roleDataToSend = {
        ...roleData,
        code_role: roleData.code_role.toUpperCase(),
      };

      await adminServices
        .createRole(roleDataToSend)
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          setRoleData({
            name: "",
            code_role: "",
            status: true,
          });
          getAllRoles();
        })
        .catch((res: any) => toast.error(res.message));
    } catch (error) {
      toast.error("An error occurred during role creation");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className="col-lg-4">
      <div className="card stretch stretch-full">
        <div className="card-header">Add a New Role</div>
        <div className="card-body">
          <div className="mb-4">
            <input
              type="text"
              name="name"
              id="name"
              value={roleData.name}
              placeholder="Name"
              className="form-control"
              onChange={(e) => {
                setRoleData((prevRoleData) => ({
                  ...prevRoleData,
                  name: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              name="code_role"
              id="code_role"
              value={roleData.code_role}
              placeholder="Code Role"
              className="form-control text-uppercase"
              onChange={(e) => {
                setRoleData((prevRoleData) => ({
                  ...prevRoleData,
                  code_role: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-4">
            <Select
              options={options}
              defaultValue={options[0]}
              onChange={(selectedOption) => {
                setRoleData((prevRoleData) => ({
                  ...prevRoleData,
                  status: selectedOption?.value === "active",
                }));
              }}
            />
          </div>
          <button
            className="btn btn-primary ms-auto"
            onClick={handleCreate}
            disabled={!roleData.name || !roleData.code_role || isloading}
          >
            {isloading ? "Loading..." : "Add New"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRoleForm;
