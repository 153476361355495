import axiosInstance from "./axiosInstance";

const changePassword = async (data: {
  oldPassword: string;
  newPassword: string;
}) => {
  const response = await axiosInstance.post("auth/change-password", data);
  return response;
};

const getUsersList = async () => {
  const response = await axiosInstance.get("user");
  return response.data;
};

const desactivateUser = async () => {
  const response = await axiosInstance.get("user/desable/account");
  return response.data;
};

export const UserService = {
  changePassword,
  getUsersList,
};
