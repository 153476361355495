import React from "react";
import { UilUser, UilEnvelope, UilPhoneAlt } from "@iconscout/react-unicons";

type CreateContactFormProps = {
  formik: any;
};

const CreateContactForm: React.FC<CreateContactFormProps> = ({ formik }) => {
  return (
    <div className="col-lg-5">
      <div className="col-12 mb-4 ">
        <label htmlFor="firstname" className="fw-semibold">
          Firstname:{" "}
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="text-danger d-inline">
              ({formik.errors.firstname})
            </div>
          ) : null}
        </label>
        <div className="input-group">
          <div className="input-group-text">
            <UilUser />
          </div>
          <input
            type="text"
            className="form-control"
            id="firstname"
            placeholder="Firstname"
            {...formik.getFieldProps("firstname")}
          />
        </div>
      </div>
      <div className="col-12 mb-4 ">
        <label htmlFor="lastname" className="fw-semibold">
          Lastname:{" "}
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="text-danger d-inline">
              ({formik.errors.lastname})
            </div>
          ) : null}
        </label>
        <div className="input-group">
          <div className="input-group-text">
            <UilUser />
          </div>
          <input
            type="text"
            className="form-control"
            id="lastname"
            placeholder="Lastname"
            {...formik.getFieldProps("lastname")}
          />
        </div>
      </div>
      <div className="col-12 mb-4 ">
        <label htmlFor="email" className="fw-semibold">
          Email:{" "}
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger d-inline">({formik.errors.email})</div>
          ) : null}
        </label>
        <div className="input-group">
          <div className="input-group-text">
            <UilEnvelope />
          </div>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
          />
        </div>
      </div>
      <div className="col-12 mb-4 ">
        <label htmlFor="phone" className="fw-semibold">
          Phone:*
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-danger d-inline">({formik.errors.phone})</div>
          ) : null}
        </label>
        <div className="input-group">
          <div className="input-group-text">
            <UilPhoneAlt />
          </div>
          <input
            type="text"
            className="form-control"
            id="phone"
            placeholder="Phone"
            {...formik.getFieldProps("phone")}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContactForm;
