import React, { useEffect, useState } from "react";
import moment from "moment";
import { whatsappService } from "../../services/whatsappService";

export type Session = {
  id: number;
  phoneNumber: string;
  initializedAt: string;
  status: boolean;
};

type UserWhatsappSessionsProps = {
  handleReuseSession: (session: Session) => void;
};

const UserWhatsappSessions: React.FC<UserWhatsappSessionsProps> = ({
  handleReuseSession,
}) => {
  const [sessions, setSessions] = useState<Session[] | null>(null);
  const [loading, setLoading] = useState(true); // Default loading state to true

  const fetchUserSessions = async () => {
    try {
      const res = await whatsappService.getUserInitSessions();
      if (res.status !== -1) {
        setSessions(res.data);
      } else {
        setSessions([]);
      }
    } catch (error) {
      console.error("Failed to fetch sessions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserSessions();
  }, []);

  return (
    <div className="row note-has-grid" id="note-full-container">
      {loading && (
        <div className="d-flex gap-2 justify-content-center align-items-center mt-4">
          <span className="loader text-primary"></span>
          <h5 className="text-center m-0">Fetching your sessions...</h5>
        </div>
      )}
      {!loading && sessions?.length === 0 && (
        <div className="d-flex gap-2 justify-content-center align-items-center mt-4">
          <h5 className="text-center m-0">No sessions available.</h5>
        </div>
      )}
      {sessions?.map((session) => (
        <div
          key={session.id}
          className={`col-xxl-4 col-xl-6 col-lg-4 col-sm-6 single-note-item all-category ${
            session.status ? "note-important" : "note-business"
          } note-tasks`}
        >
          <div className="card card-body mb-4 stretch stretch-full">
            <span className="side-stick"></span>
            <h5
              className="note-title text-truncate w-75 mb-1"
              data-noteheading={session.phoneNumber}
            >
              {session.phoneNumber}
              <span
                className={`badge ${
                  session.status
                    ? "bg-soft-success text-success"
                    : "bg-soft-warning text-warning"
                } ms-2`}
              >
                {session.status ? "Active" : "Inactive"}
              </span>
            </h5>
            <p className="fs-11 text-muted note-date">
              Initialized on{" "}
              {moment(session.initializedAt).format("DD/MM/YYYY HH:mm")}
            </p>
            <div className="d-flex align-items-center gap-1">
              <div className="ms-auto">
                <button
                  onClick={() => handleReuseSession(session)}
                  className="btn btn-primary"
                >
                  Reuse Session
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserWhatsappSessions;
