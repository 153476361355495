import React, { useState } from "react";
import { User } from "../models/User";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

type ProfileDetailsProps = {
  user: User | null;
};

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ user }) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const firstLetter = user && user.firstname ? user.firstname.charAt(0) : "";

  const handleSubmit = (values: any) => {
    try {
      if (!formik.dirty) return;
      setSubmitting(true);
      const userdata: Partial<User> = {
        firstname: values.firstName,
        lastname: values.lastName,
        password: values.password,
        phone: values.phone,
        country: values.country,
        company: values.company,
        address: values.address,
      };
      dispatch(updateUser(userdata) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success("Account updated sucessfully");
        })
        .catch((err: any) => {
          toast.error("Unable to update user. Please try again later");
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error(
        "An error occured while updating user. Please try again later"
      );
      setSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      firstName: user?.firstname,
      lastName: user?.lastname,
      phone: user?.phone,
      country: user?.country,
      address: user?.address,
      company: user?.company,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div className="content-area" data-scrollbar-target="#psScrollbarInit">
      <div className="content-area-body">
        <div className="card mb-0">
          <div className="card-body">
            <div className="mb-5">
              <div className="wd-100 ht-100 border border-gray-2 rounded bg-primary d-flex justify-content-center align-items-center">
                <h5 className="text-center text-white ">{firstLetter}</h5>
              </div>
            </div>
            <h6>Your user code : {user?.user_code}</h6>
            <form className="w-100 mt-4 pt-2" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="firstName">Firstname</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    placeholder="Firstname"
                    id="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="lastName">Lastname</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Lastname"
                    required
                    id="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  ) : null}
                </div>
                {/* <div className="mb-3 col-md-6">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control"
                    placeholder="Email"
                    required
                    id="email"
                    type="email"
                    disabled
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div> */}
                <div className="mb-3 col-md-6">
                  <label htmlFor="phone">Phone</label>
                  <input
                    className="form-control"
                    placeholder="Phone"
                    required
                    id="phone"
                    type="tel"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger">{formik.errors.phone}</div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    required
                    id="country"
                    {...formik.getFieldProps("country")}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="text-danger">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="company">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    required
                    id="company"
                    {...formik.getFieldProps("company")}
                  />
                  {formik.touched.company && formik.errors.company ? (
                    <div className="text-danger">{formik.errors.company}</div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    required
                    id="address"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-danger">{formik.errors.address}</div>
                  ) : null}
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary successAlertMessage col-md-4 ms-auto"
                    disabled={submitting || !formik.isValid || !formik.dirty}
                  >
                    {submitting && <span className="loader"></span>}
                    <span>Save Changes</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
