import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/authService";
import toast from "react-hot-toast";
import { UilEye, UilEyeSlash } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getCurrentUser } from "../../redux/slices/authSlice";

type Props = {};

const Login = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [isPassFocused, setIsPassFocused] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.auth);

  const handleRedirection = async () => {
    let user = currentUser; // store the initial value of currentUser

    if (!currentUser) {
      try {
        // Dispatch and get the resolved user directly from the result
        user = await dispatch(getCurrentUser()).unwrap(); // unwrap to access the result
      } catch (error) {
        return;
      }
    }

    // Perform redirection based on the resolved `user` object
    if (user?.role === "ADMIN" || user?.role === "SUPER_ADMIN") {
      navigate("/client-list"); // Redirect admins to client list
    } else {
      navigate("/dashboard"); // Redirect regular users to dashboard
    }
  };

  // Redirect if user is already authenticated
  useEffect(() => {
    const checkAuthentication = async () => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        await handleRedirection(); // redirect if authenticated
      }
    };
    checkAuthentication();
  }, []);

  const handleLogin = async (values: { email: string; password: string }) => {
    try {
      setSubmitting(true);
      localStorage.removeItem("email");

      // Login user
      const res = await AuthService.loginUser(values);

      // Store authToken in localStorage
      const authToken = res.data.access_token;
      localStorage.setItem("authToken", authToken);

      // Request OTP and navigate to verification page
      await AuthService.getOtp();
      localStorage.setItem("email", values.email);
      localStorage.setItem("isVerified", res.data.isVerified);
      // Handle response
      toast.success(res.message, { duration: 5000 });

      navigate("/auth/verification");
    } catch (error: any) {
      // Handle errors from login attempt
      if (error.response) {
        // Request made and server responded with an error
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error(
          "No response received from server. Please try again later."
        );
      } else {
        // Something happened in setting up the request that triggered an error
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      return handleLogin(values);
    },
  });

  const togglePassVisibility = (e: any) => {
    e.preventDefault();
    setIsPassVisible(!isPassVisible);
  };

  const handlePassFocus = () => {
    setIsPassFocused(true);
  };
  const handlePassBlur = () => {
    setIsPassFocused(false);
  };

  return (
    <main className="auth-minimal-wrapper">
      <div className="auth-minimal-inner">
        <div className="minimal-card-wrapper">
          <div className="card mb-4 mt-5 mx-4 mx-sm-0 position-relative">
            <div className="wd-50 bg-white p-2 rounded-circle shadow-lg position-absolute translate-middle top-0 start-50">
              <img
                src="../assets/images/nufiLogo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="card-body p-sm-5">
              <h2 className="fs-20 fw-bolder mb-4 text-primary">Login</h2>
              {/* <h4 className="fs-13 fw-bold mb-2">Login to your account</h4> */}
              <p className="fs-12 fw-medium text-muted">
                Welcome to our SMS platform! Log in to reach your clients with
                personalized messages and enhance your communication.
              </p>

              <form className="w-100 mt-4 pt-2" onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    id="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <div
                    className={`d-flex pass-container rounded ${
                      isPassFocused ? "focused" : ""
                    }`}
                  >
                    <input
                      type={isPassVisible ? "text" : "password"}
                      className="form-control border-end-0 rounded-start rounded-0"
                      placeholder="Password"
                      required
                      onFocus={handlePassFocus}
                      onBlur={(e: any) => {
                        handlePassBlur();
                        formik.handleBlur(e);
                      }}
                      id="password"
                      onChange={formik.handleChange}
                    />
                    <button
                      onClick={togglePassVisibility}
                      className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                    >
                      {isPassVisible ? (
                        <UilEyeSlash />
                      ) : (
                        <UilEye className="text-primary" />
                      )}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <a
                      className="fs-11 text-primary"
                      href="auth/forgot-password"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="btn btn-lg btn-primary w-100"
                  >
                    {submitting && <span className="loader"></span>}
                    Login
                  </button>
                </div>
              </form>

              <div className="mt-5 text-muted">
                <span> Don't have an account?</span>
                <a className="fw-bold" href="register">
                  Create an Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
