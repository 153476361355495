import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UilEye, UilEyeSlash } from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import { AuthService } from "../../services/authService";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderOverlay from "../../components/Loader/LoaderOverlay";
import { logMessage } from "../../utils/logMessage";

type Props = {};

const ResetPassword = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [isNewPassVisible, setIsNewPassVisible] = useState(false);
  const [isNewPassFocused, setIsNewPassFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleNewPassFocus = () => {
    setIsNewPassFocused(true);
  };
  const handleNewPassBlur = () => {
    setIsNewPassFocused(false);
  };

  const toggleNewPassVisibility = (e: any) => {
    e.preventDefault();
    setIsNewPassVisible(!isNewPassVisible);
  };

  const handleSubmit = async (values: any) => {
    if (!formik.dirty) return;
    setSubmitting(true);
    const email = localStorage.getItem("email");
    if (!email) {
      toast.error("No email found in local storage.");
      return;
    }

    try {
      await AuthService.resetPassword({
        email: email,
        password: values.newPassword,
      })
        .then((res) => {
          toast.success("Reset Password Succesfull!", { duration: 6000 });
          formik.resetForm();
          navigate("/auth");
        })
        .catch((err) => {
          const error = err.response.data.message;
          toast.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      // Handle form submission error
      toast.error("An error occured during password reset");
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[@$!%*?&#]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const verifyToken = async () => {
      const token = searchParams.get("token");
      setIsLoading(true);
      try {
        if (token) {
          await AuthService.verifyEmailToken({ token })
            .then((res: any) => {
              if (res.status === -1) {
                toast.error(res.message);
                navigate("/auth/forgot-password");
                return;
              }
              if (!res.data.confirmEmail) {
                toast.error(res.message, { duration: 6000 });
                // navigate("/auth/forgot-password");
              }
            })
            .catch((err: any) => {
              logMessage(err);
            });
          return;
        }
        navigate("/auth/forgot-password");
      } catch (error) {
        toast.error("An error occurred. Please try again later");
      } finally {
        setIsLoading(false);
      }
    };
    verifyToken();
  }, [navigate, searchParams]);

  return (
    <main className="auth-minimal-wrapper">
      <LoaderOverlay isLoading={isLoading} />
      <div className="d-flex flex-column align-items-center gap-3 p-3">
        <img
          src="../assets/images/dark.png"
          alt="nufiSMS logo"
          style={{ height: "100px" }}
        />
        <div className="card stretch stretch-full col-md-4 mx-auto">
          <div className="card-body">
            <h3 className="text-center">Create a New Password</h3>
            <form
              className="w-100 mt-4 pt-2"
              action=""
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="newPassword">New Password</label>
                  <div
                    className={`d-flex pass-container rounded ${
                      isNewPassFocused ? "focused" : ""
                    }`}
                  >
                    <input
                      type={isNewPassVisible ? "text" : "password"}
                      className="form-control border-end-0 rounded-start rounded-0"
                      placeholder="New Password"
                      required
                      onFocus={handleNewPassFocus}
                      onBlur={(e: any) => {
                        handleNewPassBlur();
                        formik.handleBlur(e);
                      }}
                      id="newPassword"
                      onChange={formik.handleChange}
                    />
                    <button
                      onClick={toggleNewPassVisibility}
                      className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                    >
                      {isNewPassVisible ? (
                        <UilEyeSlash />
                      ) : (
                        <UilEye className="text-primary" />
                      )}
                    </button>
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-danger">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3 col-12">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password again"
                    required
                    id="confirmPassword"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-danger">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={!formik.dirty || !formik.isValid || submitting}
              >
                {submitting && <span className="loader"></span>}
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
