import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div>
      {/* <!-- [ Footer ] start --> */}
      <footer className="footer  w-100 position-absolute bottom-0">
        <p className="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
          <span>Copyright ©</span>
          <script>document.write(new Date().getFullYear());</script>
        </p>
        <div className="d-flex align-items-center gap-4">
          <span
            className="fs-11 fw-semibold text-uppercase"
          >
            Help
          </span>
          <span
            className="fs-11 fw-semibold text-uppercase"
          >
            Terms
          </span>
          <span
            className="fs-11 fw-semibold text-uppercase"
          >
            Privacy
          </span>
        </div>
      </footer>
      {/* <!-- [ Footer ] end --> */}
    </div>
  );
};

export default Footer;
