import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/DashboardCard";
import {
  UilDollarSignAlt,
  UilWhatsapp,
  UilUsersAlt,
  UilFileDownload,
} from "@iconscout/react-unicons";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../redux/slices/contactSlice";
import { AppDispatch, RootState } from "../../redux/store";
import AreaChart from "../../components/AreaChart";
import { fetchMessages } from "../../redux/slices/whatsappSlice";
import moment from "moment";
import { SMS, SMSStatus } from "../../models/SMS";
import { truncateContent } from "./WhatsappHistory";
import { getStatusBadgeClass } from "../../utils/functions";
import { Link } from "react-router-dom";

const Dashboard: React.FC = () => {
  const [period, setPeriod] = useState("month"); // Default to current week
  const [xData, setXData] = useState<string[]>([]);
  const [yData, setYData] = useState<number[]>([]);
  const dispatch: AppDispatch = useDispatch();

  const { balance } = useSelector((state: RootState) => state.account);
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const { contacts } = useSelector((state: RootState) => state.contacts);
  const { messages } = useSelector((state: RootState) => state.whatsapp);

  useEffect(() => {
    dispatch(getAllContacts());
    dispatch(
      fetchMessages({
        page: 1,
        limit: 100,
        filters: {},
      })
    );
  }, [dispatch]);

  const contactsCount = contacts.length;

  const remainingExtractions =
    currentUser?.activeSubscriptions?.remainingExtractions ?? 0;
  const totalExtractions =
    currentUser?.activeSubscriptions?.TotalExtractions ?? 0;
  const extractionsUsed = totalExtractions - remainingExtractions;
  const extractionPercentageUsed =
    totalExtractions > 0 ? (extractionsUsed / totalExtractions) * 100 : 0;

  const remainingMessages =
    currentUser?.activeSubscriptions?.remainingMessages ?? 0;
  const totalMessages = currentUser?.activeSubscriptions?.totalMessages ?? 0;
  const messagesUsed = totalMessages - remainingMessages;
  const messagePercentageUsed =
    totalMessages > 0 ? (messagesUsed / totalMessages) * 100 : 0;

  // Function to group messages by date and count them
  const groupMessagesByPeriod = (period: string) => {
    const groupedMessages = messages.reduce(
      (acc: Record<string, number>, msg) => {
        let date = moment(msg.timestamp);

        // Filter messages based on the selected period
        if (period === "week" && !date.isSame(moment(), "week")) return acc;
        if (period === "month" && !date.isSame(moment(), "month")) return acc;
        if (period === "year" && !date.isSame(moment(), "year")) return acc;

        const formattedDate = date.format("YYYY-MM-DD"); // Ensure date is a string
        acc[formattedDate] = (acc[formattedDate] || 0) + 1; // Use formatted string as key
        return acc;
      },
      {}
    );

    setXData(Object.keys(groupedMessages));
    setYData(Object.values(groupedMessages));
  };

  // Update data when period changes
  useEffect(() => {
    groupMessagesByPeriod(period);
  }, [period, messages]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content">
              <h3 className="dashboard-feature-2">Analytic Dashboard</h3>
              <div className="row ">
                <DashboardCard
                  description="Amount to Consume"
                  avatar={<UilDollarSignAlt className="text-warning" />}
                  background="warning"
                  counter={`${balance} €`}
                  width={0}
                  actionName="Top Up"
                  redirectUrl={`/settings?item=${"account-balance"}`}
                  size="col-md-6 col-xxl-3"
                />
                <DashboardCard
                  description="Total Contacts"
                  avatar={<UilUsersAlt className="text-primary" />}
                  background="success"
                  counter={contactsCount}
                  width={contactsCount}
                  actionName="Contact List"
                  redirectUrl={`/contacts`}
                  size="col-md-6 col-xxl-3"
                />
                <DashboardCard
                  description="WhatsApp Messages Left"
                  avatar={<UilWhatsapp className="text-success" />}
                  background="success"
                  counter={remainingMessages}
                  width={messagePercentageUsed}
                  actionName="View Plan"
                  redirectUrl={`/settings?item=${"billing-plans"}`}
                  showProgress={true}
                  size="col-md-6 col-xxl-3"
                />
                <DashboardCard
                  description="WhatsApp Extractions Left"
                  avatar={<UilFileDownload className="text-info" />}
                  background="info"
                  counter={remainingExtractions}
                  width={extractionPercentageUsed}
                  actionName="View Plan"
                  redirectUrl={`/settings?item=${"billing-plans"}`}
                  showProgress={true}
                  size="col-md-6 col-xxl-3"
                />
              </div>
              <div className="row flex-column mb-3">
                <div className="col-md-3 ms-auto mb-3">
                  <select
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                    className="form-select"
                  >
                    <option value="week">Current Week</option>
                    <option value="month">Current Month</option>
                    <option value="year">Current Year</option>
                  </select>
                </div>
                <div className="mx-auto">
                  <div className="card">
                    <AreaChart xData={xData} yData={yData} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mx-auto">
                  <div className="card stretch stretch-full">
                    <div>
                      <div className="card-header">
                        <h5 className="card-title">Whatsapp Message History</h5>
                        <Link className="text-primary" to={"/whatsapp-history"}>
                          View All
                        </Link>
                      </div>
                      <div className="card-body custom-card-action p-0">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0">
                            <thead>
                              <tr className="border-b">
                                <th scope="row">Message Code</th>
                                <th>Content</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Scheduled Send Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {messages && messages.length > 0 ? (
                                messages.slice(0, 10).map((message: SMS) => (
                                  <tr key={message.id}>
                                    <td>
                                      <Link
                                        to={`/whatsapp-history?message=${message.code_msg}`}
                                      >
                                        {message.code_msg}
                                      </Link>
                                    </td>
                                    <td>
                                      {truncateContent(message.smsContent, 20)}
                                    </td>
                                    <td>
                                      {moment(message.timestamp).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </td>
                                    <td>
                                      <span
                                        className={`badge ${getStatusBadgeClass(
                                          message.status as SMSStatus
                                        )}`}
                                      >
                                        {message.status}
                                      </span>
                                    </td>
                                    <td>
                                      {message.isScheduled
                                        ? moment(message.sendTime).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        : "N/A"}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Messages records found.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default Dashboard;
