import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  UilArrowRight,
  UilAlignLeft,
  UilBell,
  UilWallet,
  UilUser,
  UilSignout,
  UilEye,
  UilEyeSlash,
} from "@iconscout/react-unicons";
import "../styles/Navigation.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { fetchBalance } from "../redux/slices/accountSlice";
import { Skeleton } from "@mui/material";
import SideBar from "./navigation/SideBar";
import { getAllPackages, subscribePackage } from "../redux/slices/packageSlice";
import toast from "react-hot-toast";

type NavigationProps = {
  isMiniMenu: boolean;
  onToggleMenu: () => void;
};

const Navigation = ({ isMiniMenu, onToggleMenu }: NavigationProps) => {
  const [showBalance, setShowBalance] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { balance, isLoading } = useSelector(
    (state: RootState) => state.account
  );
  const { packages } = useSelector((state: RootState) => state.packages);

  const hasActiveSubscription = useCallback(
    () => currentUser?.activeSubscriptions,
    [currentUser]
  );

  const firstLetter =
    currentUser && currentUser.firstname ? currentUser.firstname.charAt(0) : "";

  const handleLogout = () => {
    localStorage.removeItem("authToken");
  };

  const handleSubscribe = async () => {
    try {
      setIsSubscribing(true);
      const basicPackage = packages?.find(
        (pkg) => pkg.package_code === "BASIC"
      );

      if (!currentUser || !basicPackage) return;

      const data = {
        packageId: basicPackage?.id,
        userId: currentUser.id,
      };

      const result = await dispatch(
        subscribePackage(data as { userId: number; packageId: number })
      ).unwrap();

      if (result.status === -1) {
        toast.error(
          result.message || "An error occured please try again later"
        );
      } else {
        toast.success(result.message);
        navigate("/whatsapp");
      }
    } catch (error: any) {
      toast.error(`Failed to activate free messages: ${error.message}`);
    } finally {
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(getAllPackages());
  }, [dispatch]);

  return (
    <div
      className={`navigation position-relative ${
        isMiniMenu ? "mini-menu" : ""
      }`}
    >
      <SideBar isMiniMenu={isMiniMenu} onToggleMenu={onToggleMenu} />
      <header className="custom-header position-fixed header end-0 border bg-white d-flex align-items-center justify-content-between">
        <div className="menu-toggle text-black" onClick={onToggleMenu}>
          {isMiniMenu ? <UilArrowRight /> : <UilAlignLeft />}
        </div>
        {!hasActiveSubscription() && !loading && (
          <div className="alert alert-info m-0 bg-transparent border-0 d-flex flex-column flex-md-row align-items-center justify-content-between">
            <p className="m-0 fw-bold">Enjoy 10 free WhatsApp messages!</p>
            <button
              className="btn btn-info ms-2"
              onClick={handleSubscribe}
              disabled={isLoading}
            >
              {isSubscribing && <span className="loader"></span>}
              Try it out now.
            </button>
          </div>
        )}
        <div
          className={`d-flex gap-3 align-items-center ${
            !isMiniMenu && "left-header"
          }`}
        >
          {!loading && currentUser && currentUser.role === "USER" && (
            <div className="d-none d-md-flex align-items-center gap-2 justify-content-center">
              <Link
                to={`/settings?item=${"account-balance"}`}
                className="text-muted "
              >
                {balance && Number(balance) === 0 ? (
                  <button className="btn btn-sm btn-primary">
                    <UilWallet /> Top Up
                  </button>
                ) : (
                  "Available Balance :"
                )}
              </Link>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  style={{ width: "100px" }}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <h5 className="m-0 text-primary">
                  {showBalance
                    ? `${Number(balance)?.toFixed(2) || "0.00"} €`
                    : "******"}
                </h5>
              )}
              <span
                className="cursor-pointer text-primary"
                onClick={() => setShowBalance(!showBalance)}
              >
                {showBalance ? <UilEyeSlash /> : <UilEye />}
              </span>
            </div>
          )}
          {/* <div className="avatar-image avatar-sm">
            <img
              src="https://getnexel.netlify.app/assets/vendors/img/flags/1x1/us.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <UilMoon /> */}
          <UilBell />
          <div className="dropdown nxl-h-item pe-2">
            <button
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              className="bg-transparent border-0"
            >
              <div
                style={{ height: "35px", width: "35px" }}
                className="border border-gray-2 rounded-circle bg-nf-secondary d-flex justify-content-center align-items-center"
              >
                <h5 className="text-center text-white my-auto">
                  {firstLetter}
                </h5>
              </div>
            </button>
            <div className="dropdown-menu dropdown-menu-end nxl-h-dropdown nxl-user-dropdown">
              <div className="dropdown-header">
                <div className="d-flex align-items-center">
                  <div>
                    <h6 className="text-dark mb-0">
                      {currentUser?.firstname} {currentUser?.lastname}
                    </h6>
                    <span className="fs-12 fw-medium text-muted">
                      {currentUser?.email}
                    </span>
                  </div>
                </div>
              </div>

              <Link
                to={`/settings?item=${"profile-details"}`}
                className="dropdown-item"
              >
                <UilUser />
                <span>Profile Details</span>
              </Link>
              {!loading && currentUser && currentUser.role === "USER" && (
                <Link
                  to={`/settings?item=${"account-balance"}`}
                  className="dropdown-item"
                >
                  <UilWallet />
                  <span>Account Balance</span>
                </Link>
              )}
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/auth" onClick={handleLogout}>
                <UilSignout />
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navigation;
