import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateSms,
  SendMessage,
  SendMessageToGroup,
  SMS,
} from "../../models/SMS";
import { smsService } from "../../services/smsService";
import axios from "axios";

export interface SmsState {
  sms: SMS[] | null;
  error: string | null;
}

const initialState: SmsState = {
  sms: null,
  error: null,
};

export const sendSmsToGroup = createAsyncThunk<
  SMS, // Return type
  { data: SendMessageToGroup; groupCode: string }, // Argument type
  { rejectValue: string } // ThunkApiConfig
>("sms/sendSmsToGroup", async ({ data, groupCode }, { rejectWithValue }) => {
  try {
    const response = await smsService.sendSmsToGroup(data, groupCode);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data as string);
      }
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("An unexpected error occurred");
    }
  }
});

export const sendSmsToContacts = createAsyncThunk<
  SMS,
  { data: CreateSms },
  { rejectValue: string }
>("sms/sendSmsToContacts", async ({ data }, { rejectWithValue }) => {
  try {
    const response = await smsService.sendSmsToContacts(data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data as string);
      }
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("An unexpected error occurred");
    }
  }
});

export const sendMessage = createAsyncThunk<
  SMS,
  { data: SendMessage },
  { rejectValue: string }
>("sms/sendMessage", async ({ data }, { rejectWithValue }) => {
  try {
    const response = await smsService.sendMessage(data);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data as string);
      }
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("An unexpected error occurred");
    }
  }
});

export const getAllSms = createAsyncThunk(
  "sms/getAllSms",
  async ({
    page,
    limit,
    filters,
  }: {
    page?: number;
    limit?: number;
    filters?: any;
  }) => {
    const response = await smsService.getAllSms({ page, limit, filters });
    return response;
  }
);

const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendSmsToContacts.pending, (state) => {
        state.error = null;
      })
      .addCase(sendSmsToContacts.fulfilled, (state, action) => {
        // state.sms = [action.payload]; // Assuming sendSmsToContacts returns a single SMS object
        state.error = null;
      })
      .addCase(sendSmsToContacts.rejected, (state, action) => {
        state.error = action.payload ?? "Failed to send SMS";
      })
      .addCase(getAllSms.pending, (state) => {
        state.error = null;
      })
      .addCase(getAllSms.fulfilled, (state, action) => {
        state.sms = action.payload; // Assuming getAllSms returns an array of SMS objects
        state.error = null;
      })
      .addCase(getAllSms.rejected, (state, action) => {
        state.error = "Failed to fetch SMS";
      });
  },
});

export default smsSlice.reducer;
