import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Ensure you import the CSS for react-phone-number-input
import { AuthService } from "../../services/authService";
import { User } from "../../models/User";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  RegisterValues,
  initialValues,
  registerValidationSchema,
} from "../../validation/FormikValues";

const Register = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  // Redirect if user is already authenticated
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/dashboard"); // redirect to dashboard if authenticated
    }
  }, [navigate]);

  const handleSubmit = async (values: RegisterValues) => {
    try {
      setSubmitting(true);

      const user: Partial<User> = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        country:
          typeof values.country === "string"
            ? values.country
            : values.country.label, // Save the country label
        company: values.company,
        address: values.address,
        phone: values.phone,
      };

      const res = await AuthService.registerUser(user);
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }

      toast.success(res.message, { duration: 10000 });
      navigate("/auth");
    } catch (err: any) {
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registerValidationSchema,
    onSubmit: handleSubmit,
  });

  const customStyles = {
    option: (defaultStyles: any, state: { isSelected: any }) => ({
      ...defaultStyles,
      color: state.isSelected ? "#ff488b" : "#283c50",
      backgroundColor: "transparent",
    }),

    control: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#283c50",
      padding: "4px",
      borderColor: "#e5e7eb",
      borderRadius: "5px",
      boxShadow: "none",
    }),
  };

  return (
    <main className="auth-minimal-wrapper">
      <div className="auth-minimal-inner">
        <div className="minimal-card-wrapper">
          <div className="card mb-2 mt-5 mx-4 mx-sm-0 position-relative">
            <div className="wd-50 bg-white p-2 rounded-circle shadow-lg position-absolute translate-middle top-0 start-50">
              <img
                src="../assets/images/nufiLogo.png"
                alt="logo"
                className="img-fluid"
              />
            </div>
            <div className="card-body p-sm-5">
              <h2 className="fs-20 fw-bolder mb-2 text-primary">Register</h2>
              <p className="fs-12 fw-medium text-muted">
                Let's get you all setup, so you can verify your personal account
                and begin setting up your profile.
              </p>
              <form className="w-100 mt-2 pt-2" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="mb-2 col-md-6">
                    <label htmlFor="firstName">Firstname</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Firstname"
                      id="firstName"
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="text-danger">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label htmlFor="lastName">Lastname</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lastname"
                      id="lastName"
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="text-danger">
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-2 col-12">
                    <label htmlFor="email">Email</label>
                    <input
                      className="form-control"
                      placeholder="Email"
                      id="email"
                      type="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label htmlFor="country">Country</label>
                    <Select
                      options={countryList().getData()} // Fetch country options
                      value={formik.values.country} // Bind the selected value to formik state
                      onChange={(option) =>
                        formik.setFieldValue("country", option)
                      } // Set the country object {label, value}
                      onBlur={formik.handleBlur}
                      name="country"
                      styles={customStyles}
                      // className="form-control"
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <div className="text-danger">{formik.errors.country}</div>
                    ) : null}
                  </div>
                  <div className="mb-2 col-md-6">
                    <label htmlFor="phone">Phone Number</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      international
                      id="phone"
                      defaultCountry="CM"
                      className="form-control"
                      value={formik.values.phone}
                      onBlur={formik.handleBlur}
                      onChange={(phone) => formik.setFieldValue("phone", phone)}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger">{formik.errors.phone}</div>
                    ) : null}
                  </div>
                  <div className="mb-2 col-12">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      id="address"
                      {...formik.getFieldProps("address")}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="text-danger">{formik.errors.address}</div>
                    ) : null}
                  </div>
                  <div className="d-md-flex col-12 align-items-center">
                    <div className="mb-2 col-md-6">
                      <label htmlFor="company">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        id="company"
                        {...formik.getFieldProps("company")}
                      />
                      {formik.touched.company && formik.errors.company ? (
                        <div className="text-danger">
                          {formik.errors.company}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-2 ms-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsCondition"
                          required
                        />
                        <label
                          className="custom-control-label c-pointer text-muted"
                          htmlFor="termsCondition"
                        >
                          I agree to all the{" "}
                          <a href="/terms-and-conditions">
                            Terms &amp; Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 bg-primary"
                      disabled={submitting || !formik.isValid}
                    >
                      {submitting && <span className="loader"></span>}
                      Create Account
                    </button>
                  </div>
                </div>
              </form>
              <div className="mt-2 text-muted">
                <span>Already have an account?</span>
                <a className="fw-bold text-primary" href="auth">
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
