import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";

type VerifiedGuardProps = {
  children: React.ReactNode;
};

const VerifiedGuard: React.FC<VerifiedGuardProps> = ({ children }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  useEffect(() => {
    if (currentUser && !currentUser.isVerified) {
      toast.error(
        "Your account is not yet verified, you will be redirected to verification page",
        { duration: 6000 }
      );
      navigate("/auth/verification");
    }
    // if (currentUser) {
    //   if (currentUser.status !== "Account_active") {
    //     toast.error(
    //       "Your account is not yet verified, you will be redirected to verification page",
    //       { duration: 6000 }
    //     );
    //     navigate(`/auth/verification?requires_verification=true`);
    //   }
    // }
  }, [currentUser, navigate]);

  if (!currentUser || !currentUser.isVerified) {
    return null;
  }

  return <>{children}</>;
};

export default VerifiedGuard;
