import React from "react";

type Props = {};

const PageNotFound = (props: Props) => {
  return (
    <main className="">
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card mb-4 mt-5 mx-4 mx-sm-0 position-relative">
            <div className="card-body p-sm-5 text-center">
              <h2 className="fw-bolder mb-4" style={{ fontSize: "120px" }}>
                4<span className="text-danger">0</span>4
              </h2>
              <h4 className="fw-bold mb-2">Page Not Found</h4>
              <p className="fs-12 fw-medium text-muted">
                Sorry, the page you are looking for does not exist. Please check
                the URL or return to the homepage.
              </p>

              <div className="mt-5">
                <a className="btn btn-light-brand w-100" href="/">
                  Back Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageNotFound;
