import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { AppDispatch, RootState } from "../redux/store";
import { fetchBalance, fetchRecharges } from "../redux/slices/accountSlice";
import { loadStripe } from "@stripe/stripe-js";
import { accountService } from "../services/accountService";
import toast from "react-hot-toast";
import AccountBalanceCard from "./balance/AccountBalanceCard";
import TopUpForm from "./balance/TopUpForm";
import { Recharge } from "../models/Recharges";
import RechargeList from "./balance/RechargeList";
import ReceiptTemplate from "./balance/ReceiptTemplate";
import FlutterwaveCheckout from "./balance/FlutterwaveCheckout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

// State management using useReducer for better organization
type State = {
  showTopUp: boolean;
  loading: boolean;
  showBalance: boolean;
  amount: number | null;
  clientSecret?: string;
  currentStep: number;
};

type Action =
  | { type: "SET_SHOW_TOP_UP"; payload: boolean }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_SHOW_BALANCE"; payload: boolean }
  | { type: "SET_AMOUNT"; payload: number | null }
  | { type: "SET_CLIENT_SECRET"; payload: string }
  | { type: "SET_CURRENT_STEP"; payload: number };

const initialState: State = {
  showTopUp: false,
  loading: false,
  showBalance: false,
  amount: null,
  clientSecret: undefined,
  currentStep: 0,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_SHOW_TOP_UP":
      return { ...state, showTopUp: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SHOW_BALANCE":
      return { ...state, showBalance: action.payload };
    case "SET_AMOUNT":
      return { ...state, amount: action.payload };
    case "SET_CLIENT_SECRET":
      return { ...state, clientSecret: action.payload };
    case "SET_CURRENT_STEP":
      return { ...state, currentStep: action.payload };
    default:
      return state;
  }
}

const AccountBalance: React.FC = () => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch<AppDispatch>();
  const { balance, recharges, isLoading } = useSelector(
    (state: RootState) => state.account
  );
  const { currentUser } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    reduxDispatch(fetchBalance());
    reduxDispatch(fetchRecharges());
  }, [reduxDispatch]);

  const handleCreatePaymentIntent = async () => {
    if (!state.amount || state.amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    dispatchState({ type: "SET_LOADING", payload: true });
    try {
      const res = await accountService.createPaymentIntent({
        amount: state.amount,
        currency: "usd",
      });
      if (res.data.clientSecret) {
        dispatchState({
          type: "SET_CLIENT_SECRET",
          payload: res.data.clientSecret,
        });
        dispatchState({
          type: "SET_CURRENT_STEP",
          payload: state.currentStep + 1,
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      dispatchState({ type: "SET_LOADING", payload: false });
    }
  };

  const continueTransaction = async (recharge: Recharge) => {
    if (!recharge.stripe_payment_intent_id) {
      toast.error("Invalid transaction.");
      return;
    }

    try {
      // Call backend to retrieve the client secret for this PaymentIntent
      const res = await accountService.retrievePaymentIntent(
        recharge.stripe_payment_intent_id
      );
      const clientSecret = res.data.client_secret;

      if (clientSecret) {
        dispatchState({ type: "SET_CLIENT_SECRET", payload: clientSecret });
        dispatchState({ type: "SET_SHOW_TOP_UP", payload: true });
        dispatchState({ type: "SET_CURRENT_STEP", payload: 1 });
        dispatchState({ type: "SET_AMOUNT", payload: recharge.amount });
      } else {
        toast.error("Unable to retrieve payment information.");
      }
    } catch (error) {
      toast.error("Failed to continue transaction. Please try again.");
    }
  };

  const downloadReceipt = (recharge: Recharge) => {
    // Generate the HTML string from the React component
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <ReceiptTemplate recharge={recharge} currentUser={currentUser} />
    );

    // Create a hidden div element and set its innerHTML to the generated HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    document.body.appendChild(tempDiv);

    // Set a fixed width and height to mimic the A4 paper size
    tempDiv.style.width = "794px"; // A4 width in pixels (approximately)
    tempDiv.style.height = "1123px"; // A4 height in pixels (approximately)
    tempDiv.style.padding = "20px"; // Add padding to ensure content doesn't touch borders

    // Use html2canvas to capture the hidden div as an image
    html2canvas(tempDiv)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4");

        // Calculate the width and height of the image
        const imgWidth = 595.28;
        const pageHeight = 841.89;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        // Add the image to the PDF and handle page breaks
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // Save the PDF with a unique name
        pdf.save(`Receipt_${recharge.id}.pdf`);
      })
      .finally(() => {
        // Remove the hidden div from the document after generating the PDF
        document.body.removeChild(tempDiv);
      });
  };

  const toggleShowBalance = () => {
    dispatchState({ type: "SET_SHOW_BALANCE", payload: !state.showBalance });
  };

  return (
    <div style={{ overflowY: "auto" }}>
      {/* LoaderOverlay and other components */}
      <div className="content-area" data-scrollbar-target="#psScrollbarInit">
        <div className="content-area-body">
          {state.showTopUp ? (
            <FlutterwaveCheckout
              onBack={() =>
                dispatchState({
                  type: "SET_SHOW_TOP_UP",
                  payload: false,
                })
              }
              onSuccess={() => {
                dispatchState({ type: "SET_CURRENT_STEP", payload: 0 });
                dispatchState({ type: "SET_AMOUNT", payload: null });
                dispatchState({
                  type: "SET_SHOW_TOP_UP",
                  payload: false,
                });
              }}
            />
          ) : (
            // <TopUpForm
            //   currentStep={state.currentStep}
            //   handleBack={() =>
            //     dispatchState({
            //       type: "SET_CURRENT_STEP",
            //       payload: state.currentStep - 1,
            //     })
            //   }
            //   onBack={() =>
            //     dispatchState({
            //       type: "SET_SHOW_TOP_UP",
            //       payload: false,
            //     })
            //   }
            //   handleCreatePaymentIntent={handleCreatePaymentIntent}
            //   amount={state.amount}
            //   setAmount={(amount: number) =>
            //     dispatchState({ type: "SET_AMOUNT", payload: amount })
            //   }
            //   clientSecret={state.clientSecret}
            //   stripePromise={stripePromise}
            //   onSuccess={() => {
            //     dispatchState({ type: "SET_CURRENT_STEP", payload: 0 });
            //     dispatchState({ type: "SET_AMOUNT", payload: null });
            //     dispatchState({
            //       type: "SET_SHOW_TOP_UP",
            //       payload: false,
            //     });
            //   }}
            // />
            <div className="row flex-column">
              <AccountBalanceCard
                isLoading={isLoading}
                balance={balance}
                showBalance={state.showBalance}
                toggleShowBalance={toggleShowBalance}
                onTopUp={() =>
                  dispatchState({ type: "SET_SHOW_TOP_UP", payload: true })
                }
              />
              <RechargeList
                recharges={recharges}
                onDownloadReceipt={downloadReceipt}
                onContinueTransaction={continueTransaction}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountBalance;
