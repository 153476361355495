import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contact, ContactModel } from "../../models/Contacts";
import { contactService } from "../../services/contactService";

export interface ContactsState {
  contacts: ContactModel[];
  whatsappContacts: ContactModel[];
  error: string | null;
}

const initialState: ContactsState = {
  contacts: [],
  whatsappContacts: [],
  error: null,
};

export const createSingleContact = createAsyncThunk<ContactModel, Contact>(
  "contact/create",
  async (contact: Contact) => {
    const response = await contactService.createSingleContact(contact);
    return response;
  }
);

export const addContacts = createAsyncThunk<void, Contact[]>(
  "contact/addMultiple",
  async (contacts: Contact[], { dispatch }) => {
    await contactService.addContacts(contacts);
    // Dispatch getAllContacts after successfully adding contacts
    dispatch(getAllContacts());
  }
);

export const getAllContacts = createAsyncThunk<ContactModel[]>(
  "contact/get",
  async () => {
    const response = await contactService.getAllContacts();
    return response.data;
  }
);

export const getAllWhatsappContacts = createAsyncThunk<ContactModel[]>(
  "contact/getWhatsappContacts",
  async () => {
    const response = await contactService.getAllWhatsappContacts();
    return response.data;
  }
);

export const addContactsToGroup = createAsyncThunk<
  any,
  { contacts: Contact[]; groupCode: string }
>("contact/addToGroup", async ({ contacts, groupCode }, { dispatch }) => {
  await contactService.addContactsToGroup(contacts, groupCode);
  dispatch(getAllContacts());
});

export const editContact = createAsyncThunk<
  void,
  { contacts: ContactModel; code: string }
>("contact/edit", async ({ contacts, code }, { dispatch }) => {
  const response = await contactService.editContact(contacts, code);
  dispatch(getAllContacts()); // Dispatch to fetch all contacts after editing
  return response;
});

export const deleteContact = createAsyncThunk<void, string>(
  "contact/delete",
  async (code: string, { dispatch }) => {
    const response = await contactService.deleteContact(code);
    dispatch(getAllContacts()); // Dispatch to fetch all contacts after deletion
    return response;
  }
);

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSingleContact.pending, (state) => {
        state.error = null;
      })
      .addCase(
        createSingleContact.fulfilled,
        (state, action: PayloadAction<ContactModel>) => {
          state.contacts = state.contacts
            ? [...state.contacts, action.payload]
            : [action.payload];
          state.error = null;
        }
      )
      // .addCase(createSingleContact.rejected, (state, action) => {
      //   state.error = action.error.message || "Failed to create contact";
      // })
      .addCase(addContacts.pending, (state) => {
        state.error = null;
      })
      .addCase(addContacts.fulfilled, (state) => {
        state.error = null;
      })
      // .addCase(addContacts.rejected, (state, action) => {
      //   state.error = action.error.message || "Failed to add contacts";
      // })
      .addCase(
        getAllContacts.fulfilled,
        (state, action: PayloadAction<ContactModel[]>) => {
          state.contacts = action.payload;
          state.error = null;
        }
      )
      .addCase(getAllContacts.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch contacts";
      })
      .addCase(getAllWhatsappContacts.pending, (state) => {
        state.error = null;
      })
      .addCase(
        getAllWhatsappContacts.fulfilled,
        (state, action: PayloadAction<ContactModel[]>) => {
          state.whatsappContacts = action.payload;
          state.error = null;
        }
      )
      .addCase(getAllWhatsappContacts.rejected, (state, action) => {
        state.error =
          action.error.message || "Failed to fetch WhatsApp contacts";
      });
  },
});

export default contactsSlice.reducer;
