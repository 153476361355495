import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";
import { AuthService } from "../../services/authService";

// Define the shape of the AuthState, including the loading state
export interface AuthState {
  currentUser: User | null;
  errors: string | null;
  loading: boolean; // Loading state added
  otpVerified: boolean;
}

// Initial state with loading set to false
const initialState: AuthState = {
  currentUser: null,
  errors: null,
  loading: false,
  otpVerified:false,
};

// Thunk to fetch the current user
export const getCurrentUser = createAsyncThunk<User>(
  "auth/current",
  async () => {
    const res = await AuthService.getCurrentUser();
    return res.data;
  }
);

// Thunk to update the user and refetch the current user
export const updateUser = createAsyncThunk(
  "auth/update",
  async (user: Partial<User>, { dispatch }) => {
    const res = await AuthService.updateUser(user);
    if (res.data) {
      dispatch(getCurrentUser());
    }
    return res;
  }
);


export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (data: { email: string; otp: string }) => {
    const res = await AuthService.confirmOtp(data);
    return res.data;
  }
);


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.currentUser = null;
      state.otpVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending state for getCurrentUser
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true; // Set loading to true when the request is pending
        state.errors = null;
      })
      // Handle the fulfilled state for getCurrentUser
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false; // Set loading to false when the request is fulfilled
        state.errors = null;
        state.otpVerified = action.payload?.otpVerified || false;
      })
      // Handle the rejected state for getCurrentUser
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false; // Set loading to false when the request is rejected
        state.errors = action.error.message || "Failed to fetch user";
      })
      // Handle the pending state for updateUser
      .addCase(updateUser.pending, (state) => {
        state.loading = true; // Set loading to true when the request is pending
        state.errors = null;
      })
      // Handle the fulfilled state for updateUser
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false; // Set loading to false when the request is fulfilled
        state.errors = null;
      })
      // Handle the rejected state for updateUser
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false; // Set loading to false when the request is rejected
        state.errors = action.error.message || "Failed to update user";
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.loading = false;
        state.otpVerified = true;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error.message || "Failed to verify OTP";
      });
  },
});


export default authSlice.reducer;
