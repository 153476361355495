import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />
        </Link>
      </div>
      <div className="mt-5">
        <h2 className="text-center mb-4">
          <strong>Privacy Policy</strong>
        </h2>
        <p className="text-justify">
          Welcome to the Privacy Policy of <strong>NufiSMS</strong>. We are
          committed to protecting your personal information and ensuring your
          privacy. This Privacy Policy outlines how we collect, use, and
          safeguard your information when you use our services.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Information We Collect</strong>
        </h3>
        <p className="text-justify">
          We collect various types of information to provide and improve our
          services, including:
        </p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Information such as your
            name, email address, phone number, and company details when you
            register or interact with our platform.
          </li>
          <li>
            <strong>Usage Data:</strong> Data about your interactions with our
            platform, including log data, device information, and browsing
            history.
          </li>
          <li>
            <strong>Transactional Data:</strong> Information related to
            transactions made through our platform, including payment details
            and message history.
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>How We Use Your Information</strong>
        </h3>
        <p className="text-justify">We use your information to:</p>
        <ul>
          <li>
            Provide and improve our services, including processing messages and
            handling transactions.
          </li>
          <li>
            Communicate with you, including sending updates, notifications, and
            promotional materials.
          </li>
          <li>
            Analyze usage patterns to enhance user experience and platform
            functionality.
          </li>
          <li>
            Ensure compliance with legal obligations and protect our users and
            services.
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>How We Protect Your Information</strong>
        </h3>
        <p className="text-justify">
          We implement various security measures to protect your information,
          including:
        </p>
        <ul>
          <li>
            Using encryption protocols to secure data transmission and storage.
          </li>
          <li>
            Employing access controls to restrict unauthorized access to your
            data.
          </li>
          <li>
            Regularly updating our systems and protocols to address security
            vulnerabilities.
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>Sharing Your Information</strong>
        </h3>
        <p className="text-justify">We may share your information with:</p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Third parties that assist us in
            operating our platform and delivering services, such as payment
            processors and customer support.
          </li>
          <li>
            <strong>Legal Authorities:</strong> If required by law or in
            response to legal processes, we may disclose your information to
            comply with legal obligations.
          </li>
          <li>
            <strong>Business Transfers:</strong> In connection with mergers,
            acquisitions, or other business transactions, your information may
            be transferred as part of the deal.
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>Your Rights and Choices</strong>
        </h3>
        <p className="text-justify">You have the right to:</p>
        <ul>
          <li>
            <strong>Access:</strong> Request access to the personal information
            we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> Request correction of inaccurate or
            incomplete information.
          </li>
          <li>
            <strong>Deletion:</strong> Request the deletion of your personal
            data, subject to legal and contractual obligations.
          </li>
          <li>
            <strong>Opt-Out:</strong> Opt out of receiving marketing
            communications from us.
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>Children's Privacy</strong>
        </h3>
        <p className="text-justify">
          Our platform is not intended for children under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          we become aware that we have collected such information, we will take
          steps to delete it.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Changes to This Privacy Policy</strong>
        </h3>
        <p className="text-justify">
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the updated policy on our
          platform. Your continued use of the platform after any changes
          constitutes your acceptance of the updated policy.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Contact Us</strong>
        </h3>
        <p className="text-justify">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p className="text-justify">
          <strong>Email:</strong> support@nuifisms.com
        </p>
        <p className="text-justify">
          <strong>Address:</strong> In front of 100 papiers, Bonamoussadi,
          Douala, Cameroon
        </p>
      </div>
      <div className="mt-5 d-flex flex-column flex-md-row justify-content-between pt-2 border-top">
        <p className="text-center">
          Crafted with ❤ by{" "}
          <Link
            to={"https://digitwace.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            DigitWace Cameroun Sarl
          </Link>
        </p>
        <p className="text-center">
          © Copyright 2024 NufiSMS. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
