import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UilEye, UilEyeSlash } from "@iconscout/react-unicons";
import { UserService } from "../services/userService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type Props = {};

const ChangePassword = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [isOldPassVisible, setIsOldPassVisible] = useState(false);
  const [isNewPassVisible, setIsNewPassVisible] = useState(false);
  const [isOldPassFocused, setIsOldPassFocused] = useState(false);
  const [isNewPassFocused, setIsNewPassFocused] = useState(false);
  const navigate = useNavigate();

  const handleOldPassFocus = () => {
    setIsOldPassFocused(true);
  };
  const handleOldPassBlur = () => {
    setIsOldPassFocused(false);
  };

  const handleNewPassFocus = () => {
    setIsNewPassFocused(true);
  };
  const handleNewPassBlur = () => {
    setIsNewPassFocused(false);
  };

  const toggleOldPassVisibility = (e: any) => {
    e.preventDefault();
    setIsOldPassVisible(!isOldPassVisible);
  };

  const toggleNewPassVisibility = (e: any) => {
    e.preventDefault();
    setIsNewPassVisible(!isNewPassVisible);
  };

  const handleSubmit = async (values: any) => {
    if (!formik.dirty) return;
    setSubmitting(true);
    try {
      await UserService.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
        .then((res) => {
          if (res.data.status === -1) {
            toast.error(res.data.message, { duration: 6000 });
            return;
          }
          toast.success("Password Updated Succesfully!", { duration: 6000 });
          formik.resetForm();
          localStorage.removeItem("authToken");
          navigate("/auth");
        })
        .catch((err) => {
          const error = err.response.data.message;
          toast.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      // Handle form submission error
      toast.error("An error occured during password update");
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[@$!%*?&#]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div className="content-area" data-scrollbar-target="#psScrollbarInit">
      <div className="content-area-body">
        <h5 className="fs-20 fw-bolder my-4 text-center">Change Password</h5>
        <div className="card">
          <div className="card-body">
            <form
              className="w-100 mt-4 pt-2"
              action=""
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="oldPassword">Old Password</label>
                  <div
                    className={`d-flex pass-container rounded ${
                      isOldPassFocused ? "focused" : ""
                    }`}
                  >
                    <input
                      type={isOldPassVisible ? "text" : "password"}
                      className="form-control border-end-0 rounded-start rounded-0"
                      placeholder="Old Password"
                      required
                      onFocus={handleOldPassFocus}
                      onBlur={(e: any) => {
                        handleOldPassBlur();
                        formik.handleBlur(e);
                      }}
                      id="oldPassword"
                      onChange={formik.handleChange}
                    />
                    <button
                      onClick={toggleOldPassVisibility}
                      className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                    >
                      {isOldPassVisible ? (
                        <UilEyeSlash />
                      ) : (
                        <UilEye className="text-primary" />
                      )}
                    </button>
                  </div>
                  {formik.touched.oldPassword && formik.errors.oldPassword ? (
                    <div className="text-danger">
                      {formik.errors.oldPassword}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="newPassword">New Password</label>
                  <div
                    className={`d-flex pass-container rounded ${
                      isNewPassFocused ? "focused" : ""
                    }`}
                  >
                    <input
                      type={isNewPassVisible ? "text" : "password"}
                      className="form-control border-end-0 rounded-start rounded-0"
                      placeholder="New Password"
                      required
                      onFocus={handleNewPassFocus}
                      onBlur={(e: any) => {
                        handleNewPassBlur();
                        formik.handleBlur(e);
                      }}
                      id="newPassword"
                      onChange={formik.handleChange}
                    />
                    <button
                      onClick={toggleNewPassVisibility}
                      className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                    >
                      {isNewPassVisible ? (
                        <UilEyeSlash />
                      ) : (
                        <UilEye className="text-primary" />
                      )}
                    </button>
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-danger">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3 col-12">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password again"
                    required
                    id="confirmPassword"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-danger">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={!formik.dirty || !formik.isValid || submitting}
              >
                {submitting && <span className="loader"></span>}
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
