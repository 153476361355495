import React, { useState, useEffect } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { UilAngleLeftB } from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { adminServices } from "../../services/adminService";

type Props = {};

const customStyles = {
  option: (defaultStyles: any, state: { isSelected: any }) => ({
    ...defaultStyles,
    color: state.isSelected ? "#ff488b" : "#283c50",
    backgroundColor: "transparent",
  }),
  control: (defaultStyles: any) => ({
    ...defaultStyles,
    border: "1px solid #e5e7eb",
    boxShadow: "none",
    padding: "4px",
  }),
};

const UpdateClientBalance = (props: Props) => {
  
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const options = [
    { value: "card", label: "Card" },
    { value: "wallet", label: "Wallet" },
  ];
  const services = [
    { value: "bulk_sms", label: "Bulk SMS" },
    { value: "single_sms", label: "Single SMS" },
  ];
  const currency = [
    { value: "eur", label: "EUR" },
    { value: "usd", label: "USD" },
  ];

  const [formData, setFormData] = useState({
    paymentMethod: options[0],
    service: services[0],
    amount: "",
    currency: currency[0],
    comment: "",
  });

  const [totals, setTotals] = useState({
    totalToPay: 0,
    fees: 0,
    balance: 0,
    grandTotal: 0,
    amount:0,
  });

  useEffect(() => {
    const amount = parseFloat(formData.amount) || 0;
    const fees = amount * 0.034; // Assume 3.4% fees
    const totalToPay = amount + fees;
    const balance = totalToPay + fees;
    const grandTotal = balance;

    setTotals({
      totalToPay: totalToPay,
      fees: fees,
      balance: balance,
      amount:amount,
      grandTotal: grandTotal,
    });
  }, [formData.amount, formData.currency.value]);

  const handleChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleUpdateBalance = async () => {
    try {
      if (!id) {
        toast.error("Client code is missing.");
        return;
      }
      const amount = parseFloat(formData.amount);
      if (isNaN(amount) || amount <= 0) {
        toast.error("Please enter a valid amount.");
        return;
      }
      const response = await adminServices.updateClientBalance(id, amount);
      if (response.status === -1) {
        toast.error(response.message);
        return;
      }
      toast.success(response.message);
      navigate(-1); 
    } catch (error) {
      toast.error("An error occurred while updating the balance.");
    }
  };

 

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Update Client Balance</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(-1)}
                    >
                      <span>Back To Client Profile</span>
                    </button>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-md-7">
                  <div className="card stretch stretch-full">
                    <div className="card-body">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleUpdateBalance();
                        }}
                      >
                        <div className="row">
                          {/* <div className="mb-3 col-md-6">
                            <label htmlFor="payment-method">
                              Payment Method
                            </label>
                            <Select
                              styles={customStyles}
                              options={options}
                              defaultValue={formData.paymentMethod}
                              onChange={(selectedOption) =>
                                handleChange("paymentMethod", selectedOption)
                              }
                            />
                          </div> */}
                          {/* <div className="mb-3 col-md-6">
                            <label htmlFor="services">Services</label>
                            <Select
                              styles={customStyles}
                              options={services}
                              defaultValue={formData.service}
                              onChange={(selectedOption) =>
                                handleChange("service", selectedOption)
                              }
                            />
                          </div> */}
                          <div className="mb-3 col-md-6">
                            <label htmlFor="amount">Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount"
                              id="amount"
                              min={1}
                              value={formData.amount}
                              onChange={(e) =>
                                handleChange("amount", e.target.value)
                              }
                            />
                          </div>
                          {/* <div className="mb-3 col-md-6">
                            <label htmlFor="currency">Currency</label>
                            <Select
                              styles={customStyles}
                              options={currency}
                              defaultValue={formData.currency}
                              onChange={(selectedOption) =>
                                handleChange("currency", selectedOption)
                              }
                            />
                          </div> */}
                          {/* <div className="mb-3 col-12">
                            <label htmlFor="comment">Comment</label>
                            <textarea
                              className="form-control"
                              placeholder="Comment"
                              id="comment"
                              value={formData.comment}
                              onChange={(e) =>
                                handleChange("comment", e.target.value)
                              }
                            />
                          </div> */}
                          <div className="col-12 justify-content-end">
                            <button className="btn btn-primary"
                            disabled={!formData.amount || Number(formData.amount) <= 0}
                            >Update</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-md-5">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="">Transaction Details</h5>
                    </div>
                    <div className="card-body">
                      <ul className="list-unstyled activity-feed">
                        {/* <li className="d-flex justify-content-between feed-item feed-item-success">
                          <span className="fw-bold">Amount to Pay</span>
                          <span className="fw-bold text-black">
                            {formData.amount} {formData.currency.label}
                          </span>
                        </li> */}
                       <li className="d-flex justify-content-between feed-item feed-item-info">
                          <span className="fw-bold">Payment Method</span>
                          <span className="fw-bold text-black">
                            {/* {formData.paymentMethod.label} */}
                            ADMIN
                          </span>
                        </li>
                        {/* <li className="d-flex justify-content-between feed-item feed-item-warning">
                          <span className="fw-bold">Services</span>
                          <span className="fw-bold text-black">
                            {formData.service.label}
                          </span>
                        </li> */}
                        {/* <li className="d-flex justify-content-between feed-item feed-item-primary">
                          <span className="fw-bold">Rate</span>
                          <span className="fw-bold text-black">1 EUR</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="card-footer">
                      <ul className="list-unstyled activity-feed">
                        {/* <li className="d-flex justify-content-between feed-item feed-item-success">
                          <span className="fw-bold">
                            Total to Pay({formData.currency.label})
                          </span>
                          <span className="fw-bold text-black">
                            {totals.totalToPay.toFixed(2)}
                          </span>
                        </li> */}
                        {/* <li className="d-flex justify-content-between feed-item feed-item-info">
                          <span className="fw-bold">Fees</span>
                          <span className="fw-bold text-black">
                            {totals.fees.toFixed(2)}
                          </span>
                        </li> */}
                        {/* <li className="d-flex justify-content-between feed-item feed-item-warning">
                          <span className="fw-bold">Balance</span>
                          <span className="fw-bold text-black">
                            {totals.balance.toFixed(2)}
                          </span>
                        </li> */}
                        <hr />
                        <li className="d-flex justify-content-between feed-item feed-item-primary">
                          <span className="fw-bold">Total</span>
                          <span className="fw-bold text-black">
                            {totals.amount.toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default UpdateClientBalance;
